import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import ReactLoading from "react-loading";
import { getprovider } from "../actions/WalletConnecte";
import Web3 from "web3";
import Bostic_ABI from "../ABI/Bostic_ABI.json";
import BUSD_ABI from "../ABI/BUSD_ABI.json";
import config from "../config/config";
import copy from "copy-to-clipboard";
import { toastAlert } from "../helper/toastAlert";
import isEmpty from "../helper/isEmpty.js";
import { getDashPopInfo, getPackage, register } from "../actions/users";
import { useNavigate, useParams } from "react-router-dom";
import { getUser, getPopupInfos,GetAuthToken } from "../actions/users";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  email: "",
  password: "",
  twoFACode: "",
  phoneNo: "",
  otp: "",
  reCaptcha: "",
  roleType: 1,
  phoneCode: "",
  showPassword: false,
  isTerms: false,
};

export default function Joinnow(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  let [loader, setloader] = useState(false);
  const [address, setAddress] = useState("");
  let [copytxt, setcopytxt] = useState(false);
  let [copytxt1, setcopytxt1] = useState(false);
  const [refId, setRefId] = useState(id);
  const [plans, setPlans] = useState([]);
  const [realValue, setRealValue] = useState(0);
  const [plan1, setPlan1] = useState(realValue);
  const [plan2, setPlan2] = useState();
  const [plan3, setPlan3] = useState();
  const [plan4, setPlan4] = useState();
  const [plan5, setPlan5] = useState();
  const [plan6, setPlan6] = useState();
  const [plan7, setPlan7] = useState();
  const [planId1, setPlanId1] = useState();
  const [planId2, setPlanId2] = useState();
  const [planId3, setPlanId3] = useState();
  const [planId4, setPlanId4] = useState();
  const [planId5, setPlanId5] = useState();
  const [planId6, setPlanId6] = useState();
  const [planId7, setPlanId7] = useState();
  const [pId, setPId] = useState(1);
  const [levels, setlevels] = useState(false);
  const [plevel, setPleve] = useState();
  const [Side, setSide] = useState();
  // alert(realValue)
  const [Plevel, setPLevel] = useState(realValue);
  const [ActiveValue,setActiveValue] = useState(false)
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [status1, setStatus1] = useState(true);
  const [status2, setStatus2] = useState(false);
  const [status3, setStatus3] = useState(false);
  const [status4, setStatus4] = useState(false);
  const [status5, setStatus5] = useState(false);
  const [status6, setStatus6] = useState(false);
  const [status7, setStatus7] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [PopEnble, setPopEnable] = useState(false);
  const [Pack1, setPack1] = useState("");
  const [Pack2, setPack2] = useState("");
  const [Pack3, setPack3] = useState("");
  const [Pack4, setPack4] = useState("");
  const [Pack5, setPack5] = useState("");
  const [Pack6, setPack6] = useState("");
  const [Pack7, setPack7] = useState("");

  useEffect(() => {
    if (realValue) {
      setPLevel(realValue);
    }
  }, [realValue]);

  const { phoneNo, phoneCode } = formValue;

  if (address == id) {
    navigate("/");
  }
  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 20) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  useEffect(() => {
    init();
    getPlanPackage();
    UserGet();
    getPopup();
    GetTokens()
  }, []);

  const GetTokens = async() => {
    const { provider, address } = await getprovider();
    const payload = {
      address:address
    }
    const data = await GetAuthToken(payload)
    localStorage.setItem("Token",data.user.token)
  }

  const UserGet = async () => {
    let { provider, address } = await getprovider();
    const payload = {
      address: address,
    };
    const data = await getUser(payload);
    if (data.status == true) {
      // setUseris(true);
      navigate("/dashboard");
    }
  };

  const getPopup = async () => {
    const data = await getDashPopInfo();
    setPopEnable(data.user.Data[0].popup);
  };

  const init = async () => {
    const { provider, address } = await getprovider();
    let web3 = new Web3(provider);
    setAddress(address);
  };

  const getPlanPackage = async () => {
    try {
      const data = await getPackage();
      setPlans(data.user.Data);
      setRealValue(
        data.user.Data[0].status
          ? data.user.Data[0].planAmount
          : data.user.Data[1].status
          ? data.user.Data[1].planAmount
          : data.user.Data[2].status
          ? data.user.Data[2].planAmount
          : data.user.Data[3].status
          ? data.user.Data[3].planAmount
          : data.user.Data[4].status
          ? data.user.Data[4].planAmount
          : data.user.Data[5].status
          ? data.user.Data[5].planAmount
          : data.user.Data[6].planAmount
      );

      setActive1(
        data.user.Data[0].status
        ? true
        : data.user.Data[1].status
        ? false
        : data.user.Data[2].status
        ? false
        : data.user.Data[3].status
        ? false
        : data.user.Data[4].status
        ? false
        : data.user.Data[5].status
        ? false
        : false
      )
      setActive2(
        data.user.Data[0].status
        ? false
        : data.user.Data[1].status
        ? true
        : data.user.Data[2].status
        ? false
        : data.user.Data[3].status
        ? false
        : data.user.Data[4].status
        ? false
        : data.user.Data[5].status
        ? false
        : false
      )
      setActive3(
        data.user.Data[0].status
        ? false
        : data.user.Data[1].status
        ? false
        : data.user.Data[2].status
        ? true
        : data.user.Data[3].status
        ? false
        : data.user.Data[4].status
        ? false
        : data.user.Data[5].status
        ? false
        : false
      )
      setActive4(
        data.user.Data[0].status
        ? false
        : data.user.Data[1].status
        ? false
        : data.user.Data[2].status
        ? false
        : data.user.Data[3].status
        ? true
        : data.user.Data[4].status
        ? false
        : data.user.Data[5].status
        ? false
        : false
      )
      setActive5(
        data.user.Data[0].status
        ? false
        : data.user.Data[1].status
        ? false
        : data.user.Data[2].status
        ? false
        : data.user.Data[3].status
        ? false
        : data.user.Data[4].status
        ? true
        : data.user.Data[5].status
        ? false
        : false
      )
      setActive6(
        data.user.Data[0].status
        ? false
        : data.user.Data[1].status
        ? false
        : data.user.Data[2].status
        ? false
        : data.user.Data[3].status
        ? false
        : data.user.Data[4].status
        ? false
        : data.user.Data[5].status
        ? true
        : false
      )
      setActive7(
        data.user.Data[0].status
        ? false
        : data.user.Data[1].status
        ? false
        : data.user.Data[2].status
        ? false
        : data.user.Data[3].status
        ? false
        : data.user.Data[4].status
        ? false
        : data.user.Data[5].status
        ? false
        : true
      )

      if (data.user.Data[0].planId === 1) {
        setPlan1(data.user.Data[0].planAmount);
        setPlanId1(data.user.Data[0].planId);
        setStatus1(data.user.Data[0].status);
        setPack1(data.user.Data[0].planName);
      }
      if (data.user.Data[1].planId === 2) {
        setPlan2(data.user.Data[1].planAmount);
        setPlanId2(data.user.Data[1].planId);
        setStatus2(data.user.Data[1].status);
        setPack2(data.user.Data[1].planName);
      }
      if (data.user.Data[2].planId === 3) {
        setPlan3(data.user.Data[2].planAmount);
        setPlanId3(data.user.Data[2].planId);
        setStatus3(data.user.Data[2].status);
        setPack3(data.user.Data[2].planName);
      }
      if (data.user.Data[3].planId === 4) {
        setPlan4(data.user.Data[3].planAmount);
        setPlanId4(data.user.Data[3].planId);
        setStatus4(data.user.Data[3].status);
        setPack4(data.user.Data[3].planName);
      }
      if (data.user.Data[4].planId === 5) {
        setPlan5(data.user.Data[4].planAmount);
        setPlanId5(data.user.Data[4].planId);
        setStatus5(data.user.Data[4].status);
        setPack5(data.user.Data[4].planName);
      }
      if (data.user.Data[5].planId === 6) {
        setPlan6(data.user.Data[5].planAmount);
        setPlanId6(data.user.Data[5].planId);
        setStatus6(data.user.Data[5].status);
        setPack6(data.user.Data[5].planName);
      }
      if (data.user.Data[6].planId === 7) {
        setPlan7(data.user.Data[6].planAmount);
        setPlanId7(data.user.Data[6].planId);
        setStatus7(data.user.Data[6].status);
        setPack7(data.user.Data[6].planName);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    // e.preventDefault();
    if (e.target.name == "name") {
      setUserName(e.target.value);
    }
    // else if (e.target.name == "phone") {
    //   setUserPhone(e.target.value);
    // }
    else if (e.target.name == "email") {
      setUserEmail(e.target.value);
    } else if (e.target.id == "flexRadioDefault1") {
      setSide(e.target.value);
    } else if (e.target.id == "flexRadioDefault2") {
      setSide(e.target.value);
    } else {
      setRefId(e.target.value);
    }
  };

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } };
    }
    setFormValue(formData);
  };

  const LevelClick1 = (data, ide) => {
    setPLevel(data);
    setPId(ide);
    setActive1(true);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(false);
  };

  const LevelClick2 = (data, ide) => {
    setPLevel(data);
    setPId(ide);
    setActive2(true);
    setActive1(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(false);
  };

  const LevelClick3 = (data, ide) => {
    setPLevel(data);
    setPId(ide);
    setActive3(true);
    setActive2(false);
    setActive1(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(false);
  };
  const LevelClick4 = (data, ide) => {
    setPLevel(data);
    setPId(ide);
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(true);
    setActive5(false);
    setActive6(false);
    setActive7(false);
  };
  const LevelClick5 = (data, ide) => {
    setPLevel(data);
    setPId(ide);
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(true);
    setActive6(false);
    setActive7(false);
  };
  const LevelClick6 = (data, ide) => {
    setPLevel(data);
    setPId(ide);
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(true);
    setActive7(false);
  };
  const LevelClick7 = (data, ide) => {
    setPLevel(data);
    setPId(ide);
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(true);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function Specials(name) {
    return /[^A-Za-z._ 0-9]/g.test(name);
  }

  const RegUser = async () => {
    try {
      let { provider, address } = await getprovider();
      if (!provider && !address) {
        toastAlert("error", "Please Add Metamask Extension", "wallet");
        return;
      }
      if (!refId && config.ownerAddress != address) {
        toastAlert(
          "error",
          `Please enter default referID ${config.ownerAddress}`,
          "register"
        );
        return;
      }
      if (!userName) {
        toastAlert("error", "Please enter name", "register");
        return;
      }
      if (Specials(userName)) {
        toastAlert("error", "Specials charactors not acceptable", "register");
        return;
      }
      if (!phoneNo || phoneNo <= 0) {
        toastAlert("error", "Please enter valid mobile number", "register");
        return;
      }
      if (!isValidEmail(userEmail)) {
        toastAlert("error", "Please enter valid email", "register");
        return;
      }

      if (!Side) {
        toastAlert("error", "Please select side", "register");
        return;
      }

      let localaddress = localStorage.getItem("account");
      if (!isEmpty(localaddress)) {
        let payload = {
          address: refId,
        };
        const data = await getUser(payload);
        if (data.status == false) {
          toastAlert("error", "Referral ID not registered", "wallet");
          setloader(false);
          return;
        }
        setloader(true);
        let { provider, address } = await getprovider();
        let web3 = new Web3(provider);
        let network = await web3.eth.net.getId();
        if (config.livechainid != network) {
          toastAlert("error", "Please connect binance mainnet", "wallet");
          setloader(false);
          return;
        }

        const BusdContract = new web3.eth.Contract(
          BUSD_ABI,
          config.busdContract
        );
        console.log(BusdContract,'BusdContract');
        let busdbal = await BusdContract.methods.balanceOf(address).call();
        console.log("🚀 ~ RegUser ~ address:", address)
        busdbal = web3.utils.fromWei(busdbal);
        console.log("🚀 ~ RegUser ~ busdbal:", busdbal)
        console.log("🚀 ~ RegUser ~ Plevel:", Plevel)
        if (busdbal >= Plevel) {
          let bal = await web3.eth.getBalance(address);
          let val = web3.utils.fromWei(bal);
          var BNBLevel = Plevel * config.decimalvalues;
          BNBLevel = convert(BNBLevel);

          console.log("🚀 ~ RegUser ~ BusdContract.methods:", BusdContract.methods)


          // try{

          //   let  res = await BusdContract.methods.transfer(config.adminAddress, BNBLevel.toString()).send({
          //     from: address,
          //   }).on('transactionHash',async function(hash){
          //     // let reqData = {
          //     //   id: id,
          //     //   transactionHash: hash,
          //     // };
          //     const payload = {
          //       address: address,
          //       refaddress: refId,
          //       side: Side,
          //       plan: pId,
          //       name: userName,
          //       phone: phoneCode + " " + phoneNo,
          //       email: userEmail,
          //       transactionhash:hash
          //     };
          //     const data = register(payload);
          //     if (data) {
          //       setloader(false);
          //       if (PopEnble) {
          //         localStorage.setItem("showpopup", "yes");
          //       }
          //       // window.location.href = "/dashboard";
          //       navigate("/dashboard");
          //     }
          //   })
          // }
          // catch(err){
          //   console.log("🚀 ~ RegUser ~ err:", err)
          //     toastAlert("error", "Transaction rejected by user", "register");
          //     setloader(false);
          // }


  
          let res = await BusdContract.methods
            .transfer(config.adminAddress, BNBLevel.toString())
            .send({ from: address })
            .then((res) => {
              const payload = {
                address: address,
                refaddress: refId,
                side: Side,
                plan: pId,
                name: userName,
                phone: phoneCode + " " + phoneNo,
                email: userEmail,
                transactionhash:res.transactionHash
              };
              const data = register(payload);
              if (data) {
                setloader(false);
                if (PopEnble) {
                  localStorage.setItem("showpopup", "yes");
                }
                // window.location.href = "/dashboard";
                navigate("/dashboard");
              }
            })
            .catch((err) => {
              console.log("🚀 ~ RegUser ~ err:", err)
              toastAlert("error", "Transaction rejected by user", "register");
              setloader(false);
            });

            
        } else {
          toastAlert("error", "You don't have a balance", "register");
          setloader(false);
        }
      } else {
        toastAlert("error", "Please connect metamask", "register");
        setloader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <div className="bg_img">
        <Navbar />
        <div className="inner_wrapper">
          <div className="container">
            <div className="inner_wrapperbg">
              <div className="row">
                <div className="col-md-12">
                  <div className="title_flex">
                    <h2 className="inner_title">Register</h2>
                    <div className="wallet_div">
                      <label>Your Wallet</label>
                      <div className="inp_grp">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          defaultValue={halfAddrShow(address)}
                        />
                        <img
                          src={require("../assets/images/copy_icon.png")}
                          className="copy_icon inp_append"
                          alt="Copy"
                          data-iscapture={true}
                          data-tip="copy address"
                          onClick={() => {
                            copy(address);
                            setcopytxt(true);
                            setTimeout(function () {
                              setcopytxt(false);
                            }, 800);
                          }}
                        />
                      </div>
                      {copytxt && (
                        <p
                          style={{
                            color: "green",
                            position: "absolute",
                            right: "-32px",
                            bottom: "24px",
                            fontSize: "12px",
                          }}
                          className="copidtxt"
                        >
                          Copied
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="join_now">
                <div className="plans">
                  <h5>Select Your Packages Level</h5>
                  <div className="flex">
                    {status1 == true && (
                      <button
                        className={`box ${active1 == true ? "active" : ""}`}
                        id="package1"
                        onClick={() => LevelClick1(plan1, planId1)}
                      >
                        <p>{Pack1 ? Pack1 : ""}</p>
                        <h4>${plan1}</h4>
                      </button>
                    )}
                    {status2 == true && (
                      <button
                        className={`box ${active2 == true ? "active" : ""}`}
                        onClick={() => LevelClick2(plan2, planId2)}
                      >
                        <p>{Pack2 ? Pack2 : ""}</p>
                        <h4>${plan2}</h4>
                      </button>
                    )}
                    {status3 == true && (
                      <button
                        className={`box ${active3 == true ? "active" : ""}`}
                        onClick={() => LevelClick3(plan3, planId3)}
                      >
                        <p>{Pack3 ? Pack3 : ""}</p>
                        <h4>${plan3}</h4>
                      </button>
                    )}
                    {status4 == true && (
                      <button
                        className={`box ${active4 == true ? "active" : ""}`}
                        onClick={() => LevelClick4(plan4, planId4)}
                      >
                        <p>{Pack4 ? Pack4 : ""}</p>
                        <h4>${plan4}</h4>
                      </button>
                    )}
                    {status5 == true && (
                      <button
                        className={`box ${active5 == true ? "active" : ""}`}
                        onClick={() => LevelClick5(plan5, planId5)}
                      >
                        <p>{Pack5 ? Pack5 : ""}</p>
                        <h4>${plan5}</h4>
                      </button>
                    )}
                    {status6 == true && (
                      <button
                        className={`box ${active6 == true ? "active" : ""}`}
                        onClick={() => LevelClick6(plan6, planId6)}
                      >
                        <p>{Pack6 ? Pack6 : ""}</p>
                        <h4>${plan6}</h4>
                      </button>
                    )}
                    {status7 == true && (
                      <button
                        className={`box ${active7 == true ? "active" : ""}`}
                        onClick={() => LevelClick7(plan7, planId7)}
                      >
                        <p>{Pack7 ? Pack7 : ""}</p>
                        <h4>${plan7}</h4>
                      </button>
                    )}
                  </div>
                </div>
                <div className="form_sec">
                  <div className="mb-3">
                    <label className="form-label">Referral ID </label>
                    <br />
                    <span
                      className="small"
                      style={{
                        color: "#606060",
                        position: "relative",
                        top: "-12px",
                      }}
                    >
                      Default Id :{" "}
                      <span className="default_owner_add">
                        {config.ownerAddress}
                      </span>{" "}
                      {id ? (
                        ""
                      ) : (
                        <i
                          className="fa fa-copy"
                          onClick={() => {
                            copy(config.ownerAddress);
                            setcopytxt1(true);
                            setRefId(config.ownerAddress);
                            setTimeout(function () {
                              setcopytxt1(false);
                            }, 800);
                          }}
                          style={{
                            fontSize: "14px",
                            color: "#f8dc52",
                            position: "relative",
                            right: "-4px",
                            cursor: "pointer",
                          }}
                        ></i>
                      )}
                      {copytxt1 && (
                        <p
                          style={{
                            color: "green",
                            position: "absolute",
                            right: "-50px",
                            bottom: "-3px",
                            fontSize: "12px",
                          }}
                        >
                          Copied
                        </p>
                      )}
                    </span>
                    <input
                      type="email"
                      id="refid"
                      name="referance"
                      className="form-control primary_inp"
                      placeholder="Enter referral ID"
                      onChange={handleChange}
                      value={refId}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control primary_inp"
                      placeholder="Enter your name"
                      onChange={handleChange}
                      // value={refId}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <PhoneInput
                      country={"in"}
                      className="primary_inp"
                      placeholder="Enter phone number"
                      value={phoneCode + phoneNo}
                      onChange={handlePhoneNumber}
                    />
                    {/* <input
                      type="text"
                      id="phone"
                      name="phone"
                      className="form-control primary_inp"
                      placeholder="Enter your phone"
                      onChange={handleChange}
                      // value={refId}
                    /> */}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">E-mail</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control primary_inp"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      autoComplete="off"
                      // value={refId}
                    />
                  </div>
                  <label className="form-label">Select your Position</label>
                  <div className="d-flex">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="position"
                        id="flexRadioDefault1"
                        value="Left"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Left Side
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="position"
                        id="flexRadioDefault2"
                        value="Right"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        Right Side
                      </label>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      href="#"
                      className="primary_btn"
                      //   disabled={loader ? true : false}
                      type="button"
                      onClick={RegUser}
                    >
                      SUBMIT
                      {/* <ReactLoading type={'cylon'} color={'#FFFF'}/> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loader && (
            <div className="loader_section_">
              <ReactLoading
                type={"cylon"}
                color={"#ffffff"}
                className="loading"
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
