import React, { useEffect, useState } from "react";
import Web3 from "web3";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { getDashPopInfo } from "../actions/users";
import { postWallAddress } from "../actions/users";
import { EthereumProvider } from "@walletconnect/ethereum-provider";


export default function WalletPopup() {
  const [account, setAccount] = useState();
  const [PopEnble,setPopEnable] = useState(false)
  // let [account1, setaccount1] = useState();

  // const closePopup = () => {
  //   setPopupStatus(false);
  //   if (onClose) onClose();
  // };


  useEffect(()=>{
    getPopup()
  },[])

  const getPopup = async () => {
    const data = await getDashPopInfo()
    console.log(data,'data.user.Data.popup');
    setPopEnable(data.user.Data[0].popup)
  }

  const connectTrustwallet = async () => {
window.$("#connect_wallet_modal").modal("hide")

    var chainIDs=[56]

    if(config.EnvName!="prod"){
      chainIDs=[97]
    }
    var provider1 = await EthereumProvider.init({
      projectId: config.WC_PROJECT_ID, 
      chains: chainIDs,
      rpcMap: {
        56: "https://binance.llamarpc.com",
        97:"https://data-seed-prebsc-1-s1.bnbchain.org:8545	"
      },
      showQrModal: true
    });
    console.log("🚀 ~ getprovider ~ provider1:", provider1)


    // // var web3 = new Web3(config.liverpcurl);
    // var provider1 = new WalletConnectProvider({
    //   rpc: {
    //     [config.livechainid]: config.liverpcurl,
    //   },
    //   chainId: config.livechainid,
    // });
    provider1.on("connect", () => {
      // setTimeout(() => window.$("#connect_wallet_modal").modal("hide"), 600);
      setTimeout(() => window.location.reload(false), 1200);
    });
    await provider1.enable();
     let  web3 = new Web3(provider1);
    web3.eth.net.getId().then(async (res) => {
      if (res == 56) {
        var getaccounts = await web3.eth.getAccounts();
        localStorage.setItem("walltype", "trust");
        if(PopEnble) {
          localStorage.setItem("showpopup", "yes");
        }
        localStorage.setItem("account", getaccounts[0]);
        setAccount(getaccounts[0]);
        let payload = {
          address:getaccounts[0].toLowerCase()
        }
        const data = await postWallAddress(payload)
        console.log(data,')))');
        // localStorage.setItem("Token",data.user.token)
        toastAlert("success", "Wallet connected 🛡", "wallet");
        window.location.reload(false);
      } else {
        toastAlert("error", "Connect BSC Mainnet", "excerciseerr");
        setAccount("");
        return false;
      }
    });
  };

  const connectMetamask = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      const getAccounts = await web3.eth.getAccounts();
      web3.eth.net.getId().then(async (res) => {
        if (res.toString() === config.networkVersion) {
          toastAlert("success", "Wallet connected 🦊 ", "wallet");
          setAccount(getAccounts[0]);
          localStorage.setItem("walltype", "metamask");
          if(PopEnble) {
            localStorage.setItem("showpopup", "yes");
          }
          localStorage.setItem("account", getAccounts[0]);
          let payload = {
            address:getAccounts[0].toLowerCase()
          }
          const data = await postWallAddress(payload)
          console.log(data,'{}{}');
          // localStorage.setItem("Token",data.user.token)
          window.location.reload(false);
        } else {
          toastAlert("error", "Connect BSC Mainnet", "wallet");
          try {
            await web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [
                { chainId: Web3.utils.toHex(parseInt(config.networkVersion)) },
              ],
            });
            toastAlert("success", "Wallet connected 🦊 ", "wallet");
            setAccount(getAccounts[0]);
            localStorage.setItem("walltype", "metamask");
            localStorage.setItem("account", getAccounts[0]);
            let payload = {
              address:getAccounts[0].toLowerCase()
            }
            const data = await postWallAddress(payload)
            // localStorage.setItem("Token",data.user.token)
            console.log(data,'{}{}');
            window.location.reload(false);
          } catch (err) {
            console.log("some error on switching", err);
          }
        }
      });
    } else {
      toastAlert("error", "please add metamask Extension", "wallet");
      return;
    }
  };


  return (
    <div>
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabindex="-1"
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={connectMetamask}
                    >
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={connectTrustwallet}
                    >
                      <img
                        src={require("../assets/images/walletconnect.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
