import axios from "axios";
import config from "../config/config";

axios.defaults.baseURL = config.baseurl;
axios.defaults.headers.common['authorization'] = localStorage.getItem("Token")

export const getPackage = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/packageGet`,
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const LandRCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/lrCount`,
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const GetAuthToken = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/gettokenadd`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const postWallAddress = async (data) =>{
  console.log(data,'gggggggrrrrrrdesgrdddddddddd');
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/wallAddr`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const DynamicLR = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/DynamicLR`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const RandLCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/rlCount`,
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getInformation = async ()=>{
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/getInfo`,
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getDashPopInfo = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/getpopups`,
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const register = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/register`,
      data: data,
    });
    return {
      message: respData.data.message,
      status: respData.data.status,
      err: respData.data.err,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const ReRegisterContinu = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/reregister`,
      data: data,
    });
    return {
      message: respData.data.message,
      status: respData.data.status,
      err: respData.data.err,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const WithdrawAmount = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/withdrawAmount`,
      data: data,
    });
    return {
      message: respData.data.message,
      status: respData.data.status,
      err: respData.data.err,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const BidClaimReward = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/claimReward`,
      data: data,
    });
    return {
      message: respData.data.message,
      status: respData.data.status,
      err: respData.data.err,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/getuser`,
      data: data,
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};


export const updateUser =async (data) => {
  console.log(data,'gggggggg');
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/updateUser`,
      data: data,
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const totalPriceCalc = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/getTotal`,
      // data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const getDashPopups = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/getPopEnbles`,
      // data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getExpireDay = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/getsettings`,
      // data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const getBidWithdraws = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/getBidWithdraws`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getBidingPercentage = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/getbiddings`,
      // data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getWithdrawHis = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/getwithdraws`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const GetAllWithdraws = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/GetAllWithdraws`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getWithdraws =async(data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/getwithdrawsHis`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const HomeWith = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/homewithcount`,
      // data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getPopupInfos = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseurl}/routesUser/getDashPopup`,
      // data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const ReClaimData =async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/reClaim`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const seaarchUserTree = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/searchUser`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}

export const getPlans = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseurl}/routesUser/getplans`,
      data:data
    });
    return {
      user: respData.data,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
}
