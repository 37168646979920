let key = {};
let EnvName = "prod";
const WC_PROJECT_ID="d7b621d490d9346a3f35ebe1ab87a556"

if(EnvName == "local"){
const FRONT_URL = "http://localhost:3000/";
const backend_URL = "http://localhost:3040"
const Back_URL = "http://localhost:3040/api";   
const NETWORKVERSION = "97";
const BUSDContract = "0x5f0dA7F3bFF61CD11ff805015C0a6F2ca4e07efA";
const BosticContract = "0x19d0c49a323f015A30Bfb5c535B863F4C2Cb8c95";
const DueContract = "0xab1a806447c88D75CD8F31a7A2762a80E808cf99";
const adminAddress = "0x26F9F0c809779feA862B28a106Fd883fac4a3Fb7";
const ownerAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F"
const LiveChainID = 97;
let fee = 2500000000000000000;
let decimalvalues = 1e18;
const LiveRPCURL = "https://bscrpc.com/";

key = {
    backurl : backend_URL,
    baseurl: Back_URL,
    fronturl: FRONT_URL,
    networkVersion: NETWORKVERSION,
    bosticContract: BosticContract,
    busdContract: BUSDContract,
    DueContract:DueContract,
    adminAddress : adminAddress,
    ownerAddress:ownerAddress,
    livechainid: LiveChainID,
    decimalvalues:decimalvalues,
    liverpcurl:LiveRPCURL,
    WC_PROJECT_ID:WC_PROJECT_ID,
    EnvName:EnvName
}
} else if(EnvName == "demo"){
    const FRONT_URL = "https://bostic.alwin.io/";
    const backend_URL = "https://bosticapi.alwin.io"
    const Back_URL = "https://bosticapi.alwin.io/api";
    const NETWORKVERSION = "97";
    const BUSDContract = "0x4B4Bf5D871Cf6eC659F6a16fe9129f8F1EdF27d3";
    const BosticContract = "0xEC40D8A1EF33BeF7446a6A513dA4E323DDA7292F";
    const DueContract = "0xab1a806447c88D75CD8F31a7A2762a80E808cf99";
    const adminAddress = "0x26F9F0c809779feA862B28a106Fd883fac4a3Fb7";
    const ownerAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F"
    const LiveChainID = 97;
    let fee = 2500000000000000000;
    let decimalvalues = 1000000000000000000;
    const LiveRPCURL = "https://bscrpc.com/";
    key = {
        backurl : backend_URL,
        baseurl: Back_URL,
        fronturl: FRONT_URL,
        networkVersion: NETWORKVERSION,
        bosticContract:BosticContract,
        busdContract: BUSDContract,
        DueContract:DueContract,
        ownerAddress:ownerAddress,
        adminAddress : adminAddress,
        livechainid: LiveChainID,
        decimalvalues:decimalvalues,
        liverpcurl:LiveRPCURL,
        WC_PROJECT_ID:WC_PROJECT_ID,
        EnvName:EnvName

    }
} else if(EnvName == "prod"){
    const FRONT_URL = "https://www.doublerich.biz/";
    const backend_URL = "https://api.doublerich.biz"
    const Back_URL = "https://api.doublerich.biz/api";
    const NETWORKVERSION = "56";
    const BUSDContract = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    const BosticContract = "0xC39c668B756A756e8683a48b56e4C8E855d527d0";
    const DueContract = "0x7b2de3fa70d332b0392a15807ddb57f84b613a06";
    const adminAddress = "0x8De1987Ab9b1E1e26c4273fE1c28A9E5FE88138B";
    const ownerAddress = "0x8De1987Ab9b1E1e26c4273fE1c28A9E5FE88138B"
    const LiveChainID = 56;
    let fee = 2500000000000000000;
    let decimalvalues = 1000000000000000000;
    // const LiveRPCURL = "https://bsc-dataseed1.binance.org";
    const LiveRPCURL = "https://binance.llamarpc.com";
    key = {
        backurl : backend_URL,
        baseurl: Back_URL,
        fronturl: FRONT_URL,
        networkVersion: NETWORKVERSION,
        bosticContract:BosticContract,
        busdContract: BUSDContract,
        DueContract:DueContract,
        ownerAddress:ownerAddress,
        adminAddress : adminAddress,
        livechainid: LiveChainID,
        decimalvalues:decimalvalues,
        liverpcurl:LiveRPCURL,
        WC_PROJECT_ID:WC_PROJECT_ID,
        EnvName:EnvName

    }
}

export default key;
