import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import WalletPopup from "../components/WalletPopup.js";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  getUser,
  totalPriceCalc,
  getPackage,
  HomeWith,
} from "../actions/users";
import { getprovider } from "../actions/WalletConnecte";
import $ from "jquery";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Home(props) {
  var navigate = useNavigate();
  const [userIs, setUseris] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [newCommer, setNewCommer] = useState(0);
  const [AllBostic, setAllBostic] = useState(0);
  const [AllDue, setAllDue] = useState(0);
  const [Plann1, setPlannn1] = useState("");
  const [Plann2, setPlannn2] = useState("");
  const [Plann3, setPlannn3] = useState("");
  const [Plann4, setPlannn4] = useState("");
  const [Plann5, setPlannn5] = useState("");
  const [Plann6, setPlannn6] = useState("");
  const [Plann7, setPlannn7] = useState("");
  const [Pack1, setPack1] = useState("");
  const [Pack2, setPack2] = useState("");
  const [Pack3, setPack3] = useState("");
  const [Pack4, setPack4] = useState("");
  const [Pack5, setPack5] = useState("");
  const [Pack6, setPack6] = useState("");
  const [Pack7, setPack7] = useState("");
  const [status1, setStatus1] = useState(true);
  const [status2, setStatus2] = useState(false);
  const [status3, setStatus3] = useState(false);
  const [status4, setStatus4] = useState(false);
  const [status5, setStatus5] = useState(false);
  const [status6, setStatus6] = useState(false);
  const [status7, setStatus7] = useState(false);

  const getPkgse = async () => {
    const data = await getPackage();
    setPlannn1(data.user.Data[0].planAmount);
    setPlannn2(data.user.Data[1].planAmount);
    setPlannn3(data.user.Data[2].planAmount);
    setPlannn4(data.user.Data[3].planAmount);
    setPlannn5(data.user.Data[4].planAmount);
    setPlannn6(data.user.Data[5].planAmount);
    setPlannn7(data.user.Data[6].planAmount);
    setPack1(data.user.Data[0].planName);
    setPack2(data.user.Data[1].planName);
    setPack3(data.user.Data[2].planName);
    setPack4(data.user.Data[3].planName);
    setPack5(data.user.Data[4].planName);
    setPack6(data.user.Data[5].planName);
    setPack7(data.user.Data[6].planName);
    setStatus1(data.user.Data[0].status);
    setStatus2(data.user.Data[1].status);
    setStatus3(data.user.Data[2].status);
    setStatus4(data.user.Data[3].status);
    setStatus5(data.user.Data[4].status);
    setStatus6(data.user.Data[5].status);
    setStatus7(data.user.Data[6].status);
  };

  const TotalBosDue = async () => {
    const totalAmounts = await totalPriceCalc();
    const totalWith = await HomeWith();
    const Bostic = totalWith?.user?.Data[0]?.totalRewards;
    const Due = totalWith?.user?.Data1?.[0]?.totalRewards;
    setAllBostic(
      totalWith?.user?.Data.length > 0
        ? totalAmounts?.user?.Data[0]?.totalBosAmount + Bostic
        : totalAmounts?.user?.Data[0]?.totalBosAmount
    );
    setAllDue(
      totalWith?.user?.Data1.length > 0
        ? totalAmounts?.user?.Data[0]?.totalDueAmount + Due
        : totalAmounts?.user?.Data[0]?.totalDueAmount
    );
    setUserCount(totalAmounts?.user?.allData?.length);
    setNewCommer(totalAmounts?.user?.last24Hrs?.length);
  };

  const UserGet = async () => {
    // let { provider, address } = await getprovider();
    let { provider, address } = await getprovider();
    const payload = {
      address: address,
    };
    const data = await getUser(payload);
    if (data.status == true) {
      setUseris(true);
    } else {
      setUseris(false);
    }
  };

  useEffect(() => {
    TotalBosDue();
    UserGet();
    getPkgse();
    //loadScript();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 3600000);
  }, []);

  // function loadScript() {
  //   $(window).scroll(function () {
  //     var scroll = $(window).scrollTop();
  //     if (scroll >= 100) {
  //       $(".coin").addClass("fixed_navbar");
  //     } else {
  //       $(".coin").removeClass("fixed_navbar");
  //     }
  //   });
  // }
  const ConnectDash = () => {
    navigate("/dashboard");
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner">
                <ScrollAnimation animateIn="slideInUp">
                  <h1>The World’s Leading Bostic Coin Matrix Platform</h1>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp">
                  <p>
                    Welcome to BosticDue website! Here, you can learn about the
                    latest developments in the world of digital currencies and
                    how to get started with buying, selling, and trading them.
                    Our platform is safe, secure, and easy to use, so you can
                    feel confident when making transactions. Explore our site to
                    learn more and start your journey into the exciting world of
                    cryptocurrency today.{" "}
                  </p>
                </ScrollAnimation>
                {localStorage.getItem("account") && (
                  <>
                    {userIs == true && (
                      <ScrollAnimation animateIn="slideInUp">
                        <button
                          onClick={() => (window.location.href = "/dashboard")}
                          className="primary_btn mb-5"
                        >
                          Dashboard
                        </button>
                      </ScrollAnimation>
                    )}
                  </>
                )}
                {localStorage.getItem("account") && (
                  <>
                    {userIs != true && (
                      <ScrollAnimation animateIn="slideInUp">
                        <button
                          onClick={() => (window.location.href = "/joinnow")}
                          className="primary_btn mb-4"
                        >
                          Join Now
                        </button>
                      </ScrollAnimation>
                    )}
                  </>
                )}
                <ScrollAnimation animateIn="slideInUp">
                  <div className="coin_wrapper">
                    <div class="coin">
                      <div class="side heads">
                        <img
                          class="imageCoin"
                          src={require("../assets/images/due_coin.png")}
                          alt="king_coin_1"
                        />
                      </div>
                      <div class="side tails">
                        <img
                          class="imageCoin"
                          src={require("../assets/images/ban_pic.png")}
                          alt="King_coin_2"
                        />
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          {/* <div id="main">
            <div className="coin">
              <div className="tails"></div>
              <div className="heads"></div>
            </div>
          </div>
          <img
            src={require("../assets/images/coin.gif")}
            className="img-fluid coin_img"
            alt="Icon"
          /> */}
        </div>
      </div>
      <div className="main_wrapper">
        {/* Community Section */}
        <section className="community_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-2">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small">International Community</h3>
                  <h2 className="main_title">Global Business</h2>
                </ScrollAnimation>
              </div>
              <div className="col-md-12 col-lg-12 col-xl-10">
                <div className="whole_box">
                  <div className="above_box">
                    <p className="para">
                      Bostic Coin marketing is powered by the revolutionary
                      smart contract technology. The code for Bostic Coin smart
                      contract can be seen here fully open, so you can be
                      completely confident in the security and long-term
                      operation of the project.
                    </p>
                  </div>
                  <div className="blw_box">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3">
                        <div className="box">
                          <img
                            src={require("../assets/images/icon_01.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <h5>Zero Risk</h5>
                          <p>
                            The profit routes from other members directly comes
                            into your personal wallet. There is no hoarding in
                            the system, the income belongs only to you.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3">
                        <div className="box">
                          <img
                            src={require("../assets/images/icon_02.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <h5>Transactions</h5>
                          <p>
                            Transactions are recorded on a public ledger called
                            a blockchain and secured using cryptography with a
                            minimal gas fee.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3">
                        <div className="box">
                          <img
                            src={require("../assets/images/icon_03.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <h5>Centralization</h5>
                          <p>
                            Our cryptocurrency is decentralized, meaning that it
                            is controlled by a distributed network of users
                            rather than a single entity. This ensures greater
                            autonomy and resistance to censorship.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3">
                        <div className="box">
                          <img
                            src={require("../assets/images/icon_04.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <h5>100% online</h5>
                          <p>
                            Making it easy for you to earn passive income from
                            anywhere. With our expert guidance and reliable
                            technology, you can build your own network and earn
                            commissions from your referrals.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Count Section */}
        <section className="counter_section">
          <div className="container">
            <div className="counter_sectionbg">
              <div className="row">
                <div className="col-md-12 col-lg-4">
                  <div className="box">
                    <div className="row">
                      <div className="col-md-6">
                        <ScrollAnimation animateIn="fadeIn">
                          <img
                            src={require("../assets/images/count_icon_participant.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <h3>
                            <CountUp end={userCount} />
                          </h3>
                          <h6>All Participants</h6>
                        </ScrollAnimation>
                      </div>
                      <div className="col-md-6">
                        <ScrollAnimation animateIn="fadeIn">
                          <img
                            src={require("../assets/images/count_icon_02.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <h3>
                            <CountUp end={newCommer} />
                          </h3>
                          <h6>24 Hours New Comers</h6>
                        </ScrollAnimation>
                      </div>
                      <div className="col-md-6">
                        <ScrollAnimation animateIn="fadeIn">
                          <img
                            src={require("../assets/images/count_icon_03.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <h3>
                            <CountUp end={AllBostic} />
                          </h3>
                          <h6>Total Earned Bostic</h6>
                        </ScrollAnimation>
                      </div>
                      <div className="col-md-6">
                        <ScrollAnimation animateIn="fadeIn">
                          <img
                            src={require("../assets/images/count_icon_04.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <h3>
                            <CountUp end={AllDue} />
                          </h3>
                          <h6>Total Earned Due</h6>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-8 m-auto">
                  <ScrollAnimation animateIn="fadeIn">
                    {/* <p>
                      * smart contract technology is a new phenomenon in the
                      modern decentralized economy.
                    </p> */}
                    <p>
                      {" "}
                      Smart contract technology is a new phenomenon in the
                      modern decentralized economy. It allows, in accordance
                      with the program code, to process and distribute the
                      financial flows of digital assets.
                    </p>
                    <p>
                      {" "}
                      All processes take place in an open, decentralized
                      blockchain network based on the Ethereum cryptocurrency,
                      the infrastructure of which supports the operation of such
                      contracts.{" "}
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="plans">
          <div className="container">
            <ScrollAnimation animateIn="fadeIn">
              <h5>Commission Payout System</h5>
              <h2>Plan Explained</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="tree">
                <img
                  src={require("../assets/images/planimg.png")}
                  className="planimg img-fluid"
                  alt="Icon"
                />
                <img
                  src={require("../assets/images/tree_structure.png")}
                  className="tree_structure img-fluid"
                  alt="Icon"
                />
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <h5>Your Packages</h5>
              <div className="flex">
                {status1 == true && (
                  <div className="box">
                    <p>{Pack1 ? Pack1 : ""}</p>
                    <h4>${Plann1}</h4>
                  </div>
                )}
                {status2 == true && (
                  <div className="box">
                    <p>{Pack2 ? Pack2 : ""}</p>
                    <h4>${Plann2}</h4>
                  </div>
                )}
                {status3 == true && (
                  <div className="box">
                    <p>{Pack3 ? Pack3 : ""}</p>
                    <h4>${Plann3}</h4>
                  </div>
                )}
                {status4 == true && (
                  <div className="box">
                    <p>{Pack4 ? Pack4 : ""}</p>
                    <h4>${Plann4}</h4>
                  </div>
                )}
                {status5 == true && (
                  <div className="box">
                    <p>{Pack5 ? Pack5 : ""}</p>
                    <h4>${Plann5}</h4>
                  </div>
                )}
                      {status6 == true && (
                  <div className="box">
                    <p>{Pack6 ? Pack6 : ""}</p>
                    <h4>${Plann6}</h4>
                  </div>
                )}
                      {status7 == true && (
                  <div className="box">
                    <p>{Pack7 ? Pack7 : ""}</p>
                    <h4>${Plann7}</h4>
                  </div>
                )}
              </div>
            </ScrollAnimation>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq_section">
          <div className="container">
            <div className="text-center">
              <ScrollAnimation animateIn="slideInUp">
                <h2 className="h2">Frequently Asked Questions?</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="slideInUp" delay={1000}>
                <p className="para">
                  You can send email with your questions and we’ll give you
                  answers -{" "}
                  <a
                    style={{ color: "#f8dc52", textDecoration: "none" }}
                    href="mailto:contactus@doublerich.biz"
                  >
                    contactus@doublerich.biz
                  </a>
                </p>
              </ScrollAnimation>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="accordion" id="faq_accodian">
                  <ScrollAnimation animateIn="fadeIn">
                    <div
                      className="card collapsed"
                      id="headingOne"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <div className="card-header">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left"
                            type="button"
                          >
                            What is ICO Bostic Coin?
                          </button>
                        </h2>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="card-body">
                          <p>
                            Wallets of coins purchased at 1 USD during the ICO
                            period are connected with specially designed
                            technical features and provide 1000% profit to coin
                            holders after 38 months.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeIn">
                    <div
                      className="card collapsed"
                      id="headingTwo"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div className="card-header">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                          >
                            Which cryptocurrency can I buy with?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="card-body">
                          <p>
                            BOSTIC COIN is created on the Binance Smart Chain
                            and can be bought using BNB and BUSD.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeIn">
                    <div
                      className="card collapsed"
                      id="headingThree"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <div className="card-header">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                          >
                            How can I participate in the Bostic Coin sale?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="card-body">
                          <p>
                            BOSTIC COIN is now accessible through the BosticDue
                            bidding mechanism and its upcoming launch of its own
                            exchange.
                          </p>
                          <p>
                            It will eventually be offered for trading and
                            buy-and-hold on other significant global exchanges.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeIn">
                    <div
                      className="card collapsed"
                      id="headingfour"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      <div className="card-header">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                          >
                            How do I benefit from the Bostic Coin?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapsefour"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="card-body">
                          <p>
                            Given that so many feature-rich and various products
                            will be built on the BOSTIC currency foundation,
                            BOSTIC will be a value-asset token that is
                            a future-proof. With the assistance of BOSTIC COIN,
                            developers have prepared more than 6 projects in 6
                            distinct phases. Six phases will be used to
                            implement the BOSTIC projects that are most crucial
                            (2026). Developers are visiting parts of the world
                            that no one has ever considered before with the aid
                            of the Internet. Developers are confident that the
                            world will embrace BOSTIC because of this.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            {/* <div className="text-center">
              <a style={{padding:'16px 50px'}} target="_blank" href="http://bosticcoin.com/" className="primary_btn">
                READ MORE
              </a>
            </div> */}
          </div>
        </section>

        <Footer />
      </div>

      {/* Connect Wallet Modal */}
      <WalletPopup />
    </div>
  );
}
