import React from 'react';

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className='footer_panel'>
          <div className='footer_panel_left'>
             <p>Copyright © {new Date().getFullYear()} <span> BosticDue</span>, All rights Reserved</p>
          </div>
          <div className='footer_panel_right'>
            <ul className='footer_social_links'>
            <li><a href="https://t.me/bosticcoin" target="_blank"><i className="fab fa-telegram"></i></a></li>
              <li><a href="https://www.facebook.com/bosticcoin/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="https://www.instagram.com/bosticcoinofficial/" target="_blank"><i class="fab fa-instagram"></i></a></li>
              <li><a href="https://twitter.com/Bosticcoin" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="https://www.linkedin.com/in/bostic-coin-58bb7624b" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="https://bosticcoinofficial.tumblr.com" target="_blank"><i class="fab fa-tumblr"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );  
}