import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Home from './pages/home.js';
import Dashboard from './pages/dashboard';
import Joinnow from './pages/joinnow';
import Profile from './pages/profile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ToastContainer />
        <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/joinnow" element={<Joinnow />}></Route>
        <Route path="/joinnow/:id" element={<Joinnow />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
