import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { getprovider } from "../actions/WalletConnecte";
import { getUser, updateUser } from "../actions/users";
import { toastAlert } from "../helper/toastAlert";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
const initialFormValue = {
  phoneNo: "",
  phoneCode: "",
  state:"",
  stateName:"",
  country:"",
  countryName:""
};
const initialFormValue0 = {
  phoneNo: "",
  phoneCode: "",
};
const initialFormValue1 = {
  phoneNo1: "",
  phoneCode1: "",
};
let countryCodeSave = "";
export default function Profile(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();

  const [dist, setDist] = useState("");
  const [nomineeName, setNomineeName] = useState("");
  const [nomineeEmail, setNomineeEmail] = useState("");
  const [nomineePhone, setNomineePhone] = useState("");
  const [AllCountries, setAllCountries] = useState(Country.getAllCountries());
  const [AllStates, setAllStates] = useState(State.getAllStates());
  const [formValue, setFormValue] = useState(initialFormValue);
  const [formValue0, setFormValue0] = useState(initialFormValue0);
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const [stateArray, setstateArray] = useState([]);
  const [cityArray, setcityArray] = useState([]);
  const [validateError, setValidateError] = useState();
  const navigate = useNavigate();
  const {
    key,
    bankId,
    bankName,
    accountNo,
    confirmAcNo,
    holderName,
    bankcode,
    state,
    stateName,
    country,
    city,
    cityName,
    countryName,
    currencyId,
    currencySymbol,
    bankAddress,
  } = formValue;
  const countryArr = [];
  const cityArr = [];
  const stateArr = [];
  const countryDetails = Country.getAllCountries();
  const stateDetails = State.getAllStates();
  const cityDetails = City.getAllCities();

  countryDetails.map((data, i) => {
    countryArr.push({
      value: data.isoCode,
      label: data.name,
      name: data.name,
    });
  });



  const countryChange = async(e) => {
    e.preventDefault()
    const { name, value } = e.target;
    if (!value) return;
    setstateArray([]);
    setcityArray([]);
    var getCountry =await countryArr.find((ele) => ele.value == value);
    updateStates(value);
    let formData = {
      ...formValue,
      ...{
        country: getCountry.value,
        countryName: getCountry.name,
        stateArray,
        cityArray,
      },
    };
    setFormValue(formData);
    // setValidateError(validation(formData));
    if (validateError) {
      setValidateError({});
    }
  };


  const updateStates = (countryCode) => {
    countryCodeSave = countryCode;
    const stateFillter = stateDetails.filter((data) => {
      return data.countryCode == countryCode;
    });
    stateFillter.map((data, i) => {
      stateArr.push({
        value: data.isoCode,
        name: data.name,
        label: data.name,
      });
    });
    if (stateArr.length > 0) {
      setstateArray(stateArr);
    }
  };
  const updateCity = (stateCode) => {
    const cityillter = cityDetails.filter((data) => {
      return data.stateCode == stateCode && data.countryCode == countryCodeSave;
    });

    cityillter.map((data, i) => {
      cityArr.push({
        value: data.name,
        name: data.name,
        label: data.name,
      });
      if (cityArr.length > 0) {
        setcityArray(cityArr);
      }
    });
  };

  const stateChange = (e) => {
    const { name, value } = e.target;
    if (!value) return;
    setcityArray([]);
    var getState = stateArray.find((e) => e.value == value);
    updateCity(value);
    let formData = {
      ...formValue,
      ...{
        state: getState.value,
        stateName: getState.name,
        stateArray,
        cityArray,
      },
    };
    setFormValue(formData);
    // setValidateError(validation(formData));
    if (validateError) {
      setValidateError({});
    }
  };

  const { phoneNo, phoneCode } = formValue0;

  const { phoneNo1, phoneCode1 } = formValue1;

  useEffect(() => {
    if (localStorage.getItem("account")) {
      init();
      // loadTrustwallet()
    }
  }, []);

  const init = async () => {
    let { provider, address } = await getprovider();

    const payload = {
      address: address,
    };
    const data = await getUser(payload);
    if (data.status === false) {
      navigate("/");
      return;
    }
    setName(data.user.Data.userName);
    setEmail(data.user.Data.useremail);
    setPhone(data.user.Data.userPhone);
    // setState(data.user.Data?.userState);
    // setCountry(data.user.Data?.userCountry);
    let formData = {
      ...formValue,
      ...{
        country: data.user.Data?.userCountryID,
        countryName:data.user.Data?.userCountry,
        state: data.user.Data?.userStateID,
      },
    };
    let formData1 = {
      ...formValue1,
      ...{
        phoneNo1:data.user.Data?.userNomineeMobilePhone,
        phoneCode1:data.user.Data?.userNomineeMobileCode
      }

    }
    updateStates(data.user.Data?.userCountryID)
    setFormValue1(formData1)
    setFormValue(formData);
    setDist(data.user.Data?.userDistrict);
    setNomineeName(data.user.Data?.userNomineeName);
    setNomineeEmail(data.user.Data?.userNomineeEmail);
    // setNomineePhone(data.user.Data?.userNomineeMobile);
  };

  const handleChange = (e) => {
    // e.preventDefault();
    if (e.target.name == "name") {
      setName(e.target.value);
    } else if (e.target.name == "email") {
      setEmail(e.target.value);
    }
    // else if (e.target.name == "phone") {
    //   setPhone(e.target.value);
    // }
    else if (e.target.name == "state") {
      // setState(e.target.value);
    } else if (e.target.name == "dist") {
      setDist(e.target.value);
    } else if (e.target.name == "country") {
      // setCountry(e.target.value);
    } else if (e.target.name == "nomname") {
      setNomineeName(e.target.value);
    } else if (e.target.name == "nomemail") {
      setNomineeEmail(e.target.value);
    } else if (e.target.name == "nomphone") {
      setNomineePhone(e.target.value);
    }
  };
  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue0;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } };
    }
    setFormValue0(formData);
  };

  const handlePhoneNumber1 = (value, country) => {
    const { dialCode } = country;
    let phoneNo1 = value;
    let formData = formValue1;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode1: dialCode,
          phoneNo1: phoneNo1.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo1 } };
    }
    setFormValue1(formData);
  };

  function Specials(name) {
    return /[^A-Za-z._ 0-9]/g.test(name);
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(countryName == "") {
      toastAlert(
        "error",
        "Select country name",
        "register"
      );
      return;
    }
    if(dist == "") {
      toastAlert(
        "error",
        "Enter district name",
        "register"
      );
      return;
    }
    if(nomineeName == "") {
      toastAlert(
        "error",
        "Enter nominee name",
        "register"
      );
      return;
    }
    if(nomineeEmail == "") {
      toastAlert(
        "error",
        "Enter nominee email",
        "register"
      );
      return;
    }
    // if(phoneNo1 == "") {
    //   toastAlert(
    //     "error",
    //     "Enter nominee phone number",
    //     "register"
    //   );
    //   return;
    // }
    if (Specials(name)) {
      toastAlert(
        "error",
        "Specials charactors not acceptable name field",
        "register"
      );
      return;
    }
    if (Specials(dist)) {
      toastAlert(
        "error",
        "Specials charactors not acceptable district field",
        "register"
      );
      return;
    }
    if (Specials(nomineeName)) {
      toastAlert(
        "error",
        "Specials charactors not acceptable nominee name field",
        "register"
      );
      return;
    }
    if (!isValidEmail(nomineeEmail)) {
      toastAlert("error", "Please enter valid nominee email", "register");
      return;
    }
    if(phone === phoneCode1 +" "+phoneNo1) {
      toastAlert(
        "error",
        "User Number and Nominee Number are same please enter another Nominee Number",
        "register"
      );
      return;
    }
    let { provider, address } = await getprovider();
    let nomPhn = phoneCode1+" "+ phoneNo1
    console.log(nomPhn,nomineePhone,'ff');
    const payload = {
      name: name,
      email: email,
      phone: phoneCode + phone,
      address: address,
      state: stateName,
      stateID:state,
      countryID:country,
      dist: dist,
      country: countryName,
      nomname: nomineeName,
      nomemail: nomineeEmail,
      nomphone: nomPhn,
      nomiCode:phoneCode1,
      nomiNo:phoneNo1
    };
    const data = await updateUser(payload);
    if (data.status == true) {
      toastAlert("success", "User profile updated successfully", "wallet");
    }
  };

  return (
    <div>
      <Navbar />
      <div>
        <section className="profileform">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-md-12 mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Name</label>
                      <input
                        type="text"
                        class="form-control primary_inp"
                        placeholder=""
                        id="name"
                        name="name"
                        onChange={handleChange}
                        value={name ? name : ""}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Mobile Number</label>

                      <PhoneInput
                        className="primary_inp"
                        country={"in"}
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={handlePhoneNumber}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Email</label>
                      <input
                        type="email"
                        class="form-control primary_inp"
                        placeholder=""
                        id="email"
                        name="email"
                        onChange={handleChange}
                        value={email ? email : ""}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Country</label>
                      <select
                        // type="text"
                        class="form-control primary_inp"
                        placeholder=""
                        id="country"
                        name="country"
                        onChange={countryChange}
                        value={country}
                      >
                        <option style={{ background: "#111111" }} selected>
                          Select Country
                        </option>
                        {countryArr.map((country) => {
                          return (
                            <option
                              style={{ background: "#111111" }}
                              value={country.value}
                            >
                              {country.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">State</label>
                      <select
                        // type="text"
                        class="form-control primary_inp"
                        placeholder=""
                        id="state"
                        name="state"
                        onChange={stateChange}
                        value={state}
                      >
                        <option style={{ background: "#111111" }} selected>
                          Select State
                        </option>
                        {stateArray.map((state) => {
                          return (
                            <option
                              style={{ background: "#111111" }}
                              value={state.value}
                            >
                              {state.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">District </label>
                      <input
                        type="text"
                        class="form-control primary_inp"
                        placeholder=""
                        id="dist"
                        name="dist"
                        onChange={handleChange}
                        value={dist ? dist : ""}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Nominee Name</label>
                      <input
                        type="text"
                        class="form-control primary_inp"
                        placeholder=""
                        id="nomname"
                        name="nomname"
                        onChange={handleChange}
                        value={nomineeName ? nomineeName : ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Nominee Email</label>
                      <input
                        type="text"
                        class="form-control primary_inp"
                        placeholder=""
                        id="nomemail"
                        name="nomemail"
                        onChange={handleChange}
                        value={nomineeEmail ? nomineeEmail : ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Nominee Mobile</label>
                      {/* <input
                        type="text"
                        class="form-control primary_inp"
                        placeholder=""
                        id="nomphone"
                        name="nomphone"
                        onChange={handleChange}
                        value={nomineePhone ? nomineePhone : ""}
                      /> */}
                      {console.log(nomineePhone,'nomineePhone')}
                      <PhoneInput
                        className="primary_inp"
                        country={"in"}
                        placeholder="Enter phone number"
                        value={phoneCode1 + phoneNo1}
                        onChange={handlePhoneNumber1}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      href="#"
                      class="primary_btn mt-3"
                      type="button"
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
